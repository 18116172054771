import { graphql } from 'gatsby';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import NewLayout from '../components/new_layout';
import Seo from '../components/seo';
import Certifications from '../components/v2023/Certifications/certifications';
import Identity from '../components/v2023/Identity/identity';
import LinkButton from '../components/v2023/UI/LinkButton/link-button';

const IdentityVerificationPrice = ({ data, pageContext }) => {
	const lang = pageContext.langKey;
	const page = data.page;
	const plans = ['basic', 'standard', 'premium'];

	const [showMoreMobile, setShowMoreMobile] = useState(Array(plans.length).fill(false));

	const itemValueIsEmpty = (item, plan) => {
		return !item[`${plan}_text`] && !item[`${plan}_check`];
	};
	const getItemValueByPlan = (item, plan) => {
		if (itemValueIsEmpty(item, plan)) {
			return <i className="icon-dash" />;
		}
		const response = [];
		response.push(item[`${plan}_check`] ? <i className="icon-check" /> : '');
		response.push(item[`${plan}_text`] ? item[`${plan}_text`] : '');
		return response;
	};

	const groupIsEmpty = (group = [], plan) => {
		const filtered = group.filter((item) => itemValueIsEmpty(item, plan));

		return group.length === filtered.length;
	};

	const toggleShowMoreMobile = (index) => {
		setShowMoreMobile(showMoreMobile.map((b, i) => (i === index ? !b : b)));
	};

	return (
		<NewLayout pageContext={pageContext}>
			<Seo
				lang={pageContext.langKey}
				title={page.seo.title}
				description={page.seo.meta_description}
				translates={pageContext.translates}
				image={page.seo?.image_2?.localFile?.publicURL}
			/>

			<main className="main">
				<div className="main__section main__section--100 white-section brown">
					<div className="container" data-aos="fade-up">
						<div className="tag tag--highlight">{page.tag}</div>
						<h1 className="merriweather">{page.title}</h1>
						<div className="container__text">
							<ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
						</div>
						<div className="annotation show-md">
							<ReactMarkdown children={page.featured_text} rehypePlugins={rehypeRaw} />
						</div>
						<div className="table-desktop hidden-md">
							<table className="table">
								<thead>
									<tr>
										<th>
											<div className="table__cell">
												<h6>{page.feature_text}</h6>
											</div>
										</th>
										<th>
											<div className="table__cell">
												<h3>{page.basic_plan.name}</h3>
												<div className="table__cell__details">
													<ReactMarkdown
														children={page.basic_plan.price}
														rehypePlugins={[rehypeRaw]}
													/>
													<div className="details__description">
														<ReactMarkdown
															children={page.basic_plan.description}
															rehypePlugins={[rehypeRaw]}
														/>
													</div>
												</div>
												<LinkButton
													button={page.basic_plan.button}
													className="button button--xs"
												/>
											</div>
										</th>
										<th>
											<div className="table__cell">
												<h3>{page.standard_plan.name}</h3>
												<div className="table__cell__details">
													<ReactMarkdown
														children={page.standard_plan.price}
														rehypePlugins={[rehypeRaw]}
													/>
													<div className="details__description">
														<ReactMarkdown
															children={page.standard_plan.description}
															rehypePlugins={[rehypeRaw]}
														/>
													</div>
												</div>
												<LinkButton
													button={page.standard_plan.button}
													className="button button--xs"
												/>
											</div>
										</th>
										<th>
											<div className="table__cell">
												<h3>{page.premium_plan.name}</h3>
												<div className="table__cell__details">
													<ReactMarkdown
														children={page.premium_plan.price}
														rehypePlugins={[rehypeRaw]}
													/>
													<div className="details__description">
														<ReactMarkdown
															children={page.premium_plan.description}
															rehypePlugins={[rehypeRaw]}
														/>
													</div>
												</div>
												<LinkButton
													button={page.premium_plan.button}
													className="button button--xs"
												/>
											</div>
										</th>
									</tr>
								</thead>
							</table>

							<div className="annotation">
								<ReactMarkdown children={page.featured_text} rehypePlugins={rehypeRaw} />
							</div>

							{page.plan_characteristics.map((plan_characteristic, index) => (
								<div className="table-group" key={index}>
									<h6>{plan_characteristic.name}</h6>
									<table className="table">
										<tbody>
											{plan_characteristic.items.map((item, i) => (
												<tr key={i}>
													<td>
														<div className="table__cell">
															{item.name}
															{item.tooltip && (
																<span
																	className="tooltip-icon"
																	data-tooltip={item.tooltip}
																>
																	<i className="icon-info"></i>
																</span>
															)}
														</div>
													</td>
													<td>
														<div className="table__cell">
															{getItemValueByPlan(item, 'basic')}
														</div>
													</td>
													<td>
														<div className="table__cell">
															{getItemValueByPlan(item, 'standard')}
														</div>
													</td>
													<td>
														<div className="table__cell">
															{getItemValueByPlan(item, 'premium')}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							))}
						</div>

						<div className="table-mobile show-md">
							{plans.map((plan, p) => (
								<div className="table-mobile-block" key={p}>
									<div className="tile tile--gray tile--gray-thin">
										<div className="tile__body">
											<h3>{page[`${plan}_plan`].name}</h3>
											<div className="tile__body__titles">
												<ReactMarkdown
													children={page[`${plan}_plan`].price}
													rehypePlugins={[rehypeRaw]}
												/>
											</div>
											<div className="text--gray fs-16">
												<ReactMarkdown
													children={page[`${plan}_plan`].description}
													rehypePlugins={[rehypeRaw]}
												/>
											</div>
											<LinkButton
												button={page[`${plan}_plan`].button}
												className="button button--sm"
											/>
										</div>
									</div>

									{page.plan_characteristics.map((plan_characteristic, index) => {
										return (
											!groupIsEmpty(plan_characteristic.items, plan) &&
											(index < 2 || showMoreMobile[p]) && (
												<div className="table-mobile-block__list" key={index}>
													<h5>{plan_characteristic.name}</h5>
													{plan_characteristic.items.map((item, i) => {
														return (
															!itemValueIsEmpty(item, plan) && (
																<div className="table-mobile-block__list__item" key={i}>
																	<i className="icon-check" />
																	<span>
																		{item.name}
																		{item.type === 'Text' && (
																			<span>: {item[`${plan}_text`]}</span>
																		)}
																	</span>
																</div>
															)
														);
													})}
												</div>
											)
										);
									})}

									<div className="container__see-more">
										<button
											className="button button--xl button--secondary"
											onClick={() => toggleShowMoreMobile(p)}
										>
											{showMoreMobile[p] ? page.view_less : page.view_more}
										</button>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

				<Certifications />
				<Identity lang={lang} />
			</main>
		</NewLayout>
	);
};

export default IdentityVerificationPrice;

export const identityVerificationPriceQuery = graphql`
	query ($langKey: String) {
		page: strapi2023IdentityPrices(locale: { eq: $langKey }) {
			seo {
				title
				meta_description
				image_2 {
					localFile {
						publicURL
					}
				}
			}
			tag
			title
			description
			feature_text
			basic_plan {
				name
				price
				description
				button {
					text
					url
					blank
				}
			}
			standard_plan {
				name
				price
				description
				button {
					text
					url
					blank
				}
			}
			premium_plan {
				name
				price
				description
				button {
					text
					url
					blank
				}
			}
			featured_text
			plan_characteristics {
				name
				items {
					type
					name
					tooltip
					basic_text
					basic_check
					standard_text
					standard_check
					premium_text
					premium_check
				}
			}
			view_more
			view_less
		}
	}
`;
